// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Custom
@import "node_modules/font-awesome/scss/font-awesome";
//@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "custom";
