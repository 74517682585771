:root {
    --input-padding-x: .75rem;
    --input-padding-y: .65rem;
}

html,
body {
    height: 100%;
}

.form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: 0 auto;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
    height: 45px;
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
}

body {

    background-color: white;

    // Logo
    .logo-overlay {
        position: fixed;
        top: 50px;
        right: 30px;
        z-index: 9999;
        @include media-breakpoint-up(xs) {
            max-width: 64px;
        }
        @include media-breakpoint-up(sm) {
            max-width: 64px;
        }
        @include media-breakpoint-up(md) {
            max-width: 100px;
        }
    }

    // Cookie-Banner
    .cookie-consent {
        background-color: #029834 !important;
    }

    // Navigation - START
    nav {
        ul {
            .nav-link {
                text-transform: uppercase;
            }
        }

        .custom-toggler.navbar-toggler {
            border-color: transparent;
        }
        .custom-toggler .navbar-toggler-icon {
            color: black;
        }

        ul.navbar-nav li.nav-item{
            margin: 0 20px;
        }

        ul.navbar-nav .nav-item:after {
            content: '';
            display: block;
            height: 3px;
            width: 0;
            transition: width .5s ease, background-color .5s ease;
        }
        .navbar-nav .nav-item:hover:after {
            width: 100%;
            background:#fe0000;
        }
    }
    // Navigation - End


    .content-to-center {
        margin-top: 6rem;
    }

    margin: 0;
    padding: 0;

    main {
        .maintitle {
            left: 15px;
            font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
            position: relative;
            font-size: 1.6rem;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: 0.02em;
            font-weight: 700;
        }

        .maintitle:before {
            content: "";
            position: absolute;
            left: -1rem;
            top: 0.175em;
            width: 4px;
            height: calc(100% - .3em);
            background-color: #e0110c;
        }

        .maintitle-news {
            left: 15px;
            font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
            position: relative;
            font-size: 1.9rem;
            line-height: 1;
            letter-spacing: 0.02em;
            font-weight: 700;
        }

        .maintitle-news:before {
            content: "";
            position: absolute;
            left: -1rem;
            top: 0.175em;
            width: 4px;
            height: calc(100% - .3em);
            background-color: #e0110c;
        }

        top: 50px;

        .masthead {
            max-width: 100%;
            height: 100vh;
            background-image: url('/../storage/MastHead-BG.jpg');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            color: white;
        }
        @media only screen and (max-width: 800px) {
            .masthead {
                height: 70vw;
            }
        }

        // Section Numbers - Main Page
        #numbers {
            .fa {
                color: #d10e14;
                font-size: 4rem;
            }
            p {
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 2px;
            }
        }

        // News
        #news {
            .card {
                background-color: transparent !important;
                border: 0 solid rgba(0, 0, 0, 0.125) ;
                border-radius: 0;
                .card-header {

                }

                .card-body {
                    background-color: rgba(0, 0, 0, 0.01) !important;
                }
            }
        }

                    // Page Content
                    .content-section {

                    }



                    *{
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        position: relative;
                    }

                    .cf:before,
                    .cf:after {
                        content: " "; /* 1 */
            display: table; /* 2 */
        }

        .cf:after {
            clear: both;
        }

        /**
         * For IE 6/7 only
         * Include this rule to trigger hasLayout and contain floats.
         */
        .cf {
            *zoom: 1;
        }

        /* Generic styling */

        .content{
            width: 100%;
            max-width: 1142px;
            margin: 0 auto;
            padding: 0 20px;
        }

        a:focus{
            outline: 2px dashed #f7f7f7;
        }

        @media all and (max-width: 767px){
            .content{
                padding: 0 20px;
            }
        }

        ul{
            padding: 0;
            margin: 0;
            list-style: none;
        }

        ul a{
            display: block;
            background: #ccc;
            border: 4px solid #fff;
            text-align: center;
            overflow: hidden;
            font-size: .7em;
            text-decoration: none;
            font-weight: bold;
            color: #333;
            height: 70px;
            margin-bottom: -26px;
            box-shadow: 4px 4px 9px -4px rgba(0,0,0,0.4);
            -webkit-transition: all linear .1s;
            -moz-transition: all linear .1s;
            transition: all linear .1s;
        }


        @media all and (max-width: 767px){
            ul a{
                font-size: 1em;
            }
        }


        ul a span{
            top: 50%;
            margin-top: -0.7em;
            display: block;
        }

        /*

         */

        .administration > li > a{
            margin-bottom: 25px;
        }

        .director > li > a{
            width: 50%;
            margin: 0 auto 0px auto;
        }

        .subdirector:after{
            content: "";
            display: block;
            width: 0;
            height: 130px;
            background: red;
            border-left: 4px solid #fff;
            left: 45.45%;
            position: relative;
        }

        .subdirector,
        .departments{
            position: absolute;
            width: 100%;
        }

        .subdirector > li:first-child,
        .departments > li:first-child{
            width: 18.59894921190893%;
            height: 64px;
            margin: 0 auto 92px auto;
            padding-top: 25px;
            border-bottom: 4px solid white;
            z-index: 1;
        }

        .subdirector > li:first-child{
            float: right;
            right: 27.2%;
            border-left: 4px solid white;
        }

        .departments > li:first-child{
            float: left;
            left: 27.2%;
            border-right: 4px solid white;
        }

        .subdirector > li:first-child a,
        .departments > li:first-child a{
            width: 100%;
        }

        .subdirector > li:first-child a{
            left: 25px;
        }

        @media all and (max-width: 767px){
            .subdirector > li:first-child,
            .departments > li:first-child{
                width: 40%;
            }

            .subdirector > li:first-child{
                right: 10%;
                margin-right: 2px;
            }

            .subdirector:after{
                left: 49.8%;
            }

            .departments > li:first-child{
                left: 10%;
                margin-left: 2px;
            }
        }


        .departments > li:first-child a{
            right: 25px;
        }

        .department:first-child,
        .departments li:nth-child(2){
            margin-left: 0;
            clear: left;
        }

        .departments:after{
            content: "";
            display: block;
            position: absolute;
            width: 81.1%;
            height: 22px;
            border-top: 4px solid #fff;
            border-right: 4px solid #fff;
            border-left: 4px solid #fff;
            margin: 0 auto;
            top: 130px;
            left: 9.1%
        }

        @media all and (max-width: 767px){
            .departments:after{
                border-right: none;
                left: 0;
                width: 49.8%;
            }
        }

        @media all and (min-width: 768px){
            .department:first-child:before,
            .department:last-child:before{
                border:none;
            }
        }

        .department:before{
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 22px;
            border-left: 4px solid white;
            z-index: 1;
            top: -22px;
            left: 50%;
            margin-left: -4px;
        }

        .department{
            border-left: 4px solid #fff;
            width: 18.59894921190893%;
            float: left;
            margin-left: 1.751313485113835%;
            margin-bottom: 60px;
        }

        .lt-ie8 .department{
            width: 18.25%;
        }

        @media all and (max-width: 767px){
            .department{
                float: none;
                width: 100%;
                margin-left: 0;
            }

            .department:before{
                content: "";
                display: block;
                position: absolute;
                width: 0;
                height: 60px;
                border-left: 4px solid white;
                z-index: 1;
                top: -60px;
                left: 0%;
                margin-left: -4px;
            }

            .department:nth-child(2):before{
                display: none;
            }
        }

        .department > a{
            margin: 0 0 -26px -4px;
            z-index: 1;
        }

        .department > a:hover{
            height: 80px;
        }

        .department > ul{
            margin-top: 0;
            margin-bottom: 0;
        }

        .department li{
            padding-left: 25px;
            border-bottom: 4px solid #fff;
            height: 80px;
        }

        .department li a{
            background: #fff;
            top: 48px;
            position: absolute;
            z-index: 1;
            width: 90%;
            height: 60px;
            vertical-align: middle;
            right: -1px;
        }

        .department li a:hover{
            box-shadow: 8px 8px 9px -4px rgba(0,0,0,0.1);
            height: 80px;
            width: 95%;
            top: 39px;
            background-image: none!important;
        }

        /* Department/ section colors */
        .department.dep-a a{ background: #FFD600; }
        .department.dep-b a{ background: #AAD4E7; }
        .department.dep-c a{ background: #FDB0FD; }
        .department.dep-d a{ background: #A3A2A2; }
        .department.dep-e a{ background: #f0f0f0; }
    }



    // Section Footer
    #footer {
        height: 20vh;
        width: 100%;
        background-color: #f8f8f8;
    }

}


